<template>
  <div class="Contact"> 
    <div class="container">
        <b-row>
            <b-col cols lg='6' sm='12'>
                <div class="ContactInfo-block">
                    <h3 class="Header">{{acf.acf.header_1}}</h3>
                    <p class="TextPara" v-html="acf.acf.text"></p>
                </div>
            </b-col>
            <b-col cols lg='6' sm='12'>
                <Form />
            </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from '@/components/Form.vue'
export default {
  name: 'Contact',
  components: {
    Form
  },
  data: function() {
    return {
      acf: [],
    };
  },
  props: {
    msg: String
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-digital/wp-json/wp/v2/contactformsection/"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data[0]
        )); //API data wanted pulled out
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.ContactInfo-block {
    /* margin-top: 157px; */
}
h3.Header {
    color: #E83E4B;
    text-transform: uppercase;
    text-align: left;
    font-size: 30px;
    padding-bottom: 15px;
    font-weight: 100;
}
p.TextPara {
    text-align: left;
    color: #fff;
    font-size: 14px;
}
.Contact {
    background-color: #000322;
    padding-top: 50px;
    padding-bottom: 50px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
::v-deep a.nav-link {
  display: inline-block;
  color: #fff!important;
}
@media only screen and (max-width: 600px) {
  .ContactInfo-block {
    margin-top: 20px;
  }
}
</style>
