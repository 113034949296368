<template>
  <div class="blue">
      <div class="request-a-callback-form">
        <transition name="fade" mode="out-in">
          <div class="thanksBox" v-show="sent">
            <p class="thanksText">Thanks for contacting us, we will get back to you shortly...</p>
          </div>
        </transition>
        <template v-if="!sent">
          <b-form @submit="onSubmit">
            <b-row>
              <b-col md="12" cols="12" class="mobPad">
                <b-form-input
                  type="text"
                  v-model="names"
                  placeholder="Your Name"
                />
                <b-form-input
                  type="text"
                  v-model="email"
                  placeholder="Email Address"
                />
                <b-form-input
                  type="text"
                  v-model="phone"
                  placeholder="Phone Number"
                />
                <b-form-textarea
                  v-model="message"
                  rows="11"
                  cols="1"
                  class="form-control"
                >
                </b-form-textarea>
                <button
                  data-text="submit"
                  type="submit"
                  class="moreBtn"
                >
                  Submit
                </button>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </div>
  </div>
</template>

<style scoped>
textarea.form-control {
    height: 130px;
}
.thanksBox {
    text-align: center;
    border: 5px solid #fff;
    margin-top: 0em;
}
.thanksText {
    font-size: 18pt;
    color: #fff;
    font-weight: bold;
    margin-top: 1rem;
}
.blue {
    background-size: cover;
    padding-bottom: 5%;
    padding-top: 9%;
    /* padding-top: 15%; */
    text-align: left;
}

.moreBtn {
    border-color: transparent;
    font-size: 15px;
    background-color: #E83E4B;
    color: #f4f4f6;
    font-weight: 300;
    /* padding: 10px 75px; */
    border-radius: 0;
    width: 154px;
    height: 31px;
    margin-top: 30px;
}
.moreBtn:hover {
    background-color: #5B8B7E;
}

::v-deep .form-control{
    font-size:12px;
    border-radius: 0px;
    margin-bottom: 15px;
    padding: 1.375rem 0.75rem;
}

@media only screen and (max-width: 480px) {
    .mobPad{
        padding-bottom: 50px;
    }
}
</style>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      sent: false,
      contact: true,
      names: "",
      email: "",
      phone: "",
      message: "",
    };
  },
  props: ["blue"],
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("names", this.names);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("message", this.message);
      console.log(formData);
      axios
        .post(
          "https://wp-ocssport.ocs-sport.com/ocs-digital/wp-json/contact-form-7/v1/contact-forms/181/feedback",
          formData
        )
        .then((response) => {
          this.sent = true;
          this.names = "";
          this.email = "";
          this.phone = "";
          this.message = "";
          return response;
        });
    },
  },
};
</script>
