<template>
  <div class="Home">
    <div class="TopHomeback-Image">
      <div class="container">
        <div class="Home_text_block">
          <h2 class="lineOne">{{acf.acf.header_1}}</h2>
          <h2 class="lineTwo">{{acf.acf.header_2}}</h2>
          <h2 class="lineThree">{{acf.acf.header_3}}</h2>
        </div>        
      </div>
      <div class="container outerImageComp">
        <img class="computerImage" src="https://wp-ocssport.ocs-sport.com/ocs-digital/wp-content/uploads/sites/3/2023/05/OCS_Software_LandingPage_HOMEIMAGE1_692x487.png">
      </div>
    </div>
    <div class="componentHome_block">
      <div id="skills">
        <Skills />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import About from '@/components/About.vue'
import Contact from '@/components/Contact.vue'
import Skills from '@/components/Skills.vue'
export default {
  name: 'Home',
  components: {
    About,
    Contact,
    Skills
  },
  data: function() {
    return {
      acf: [],
    };
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + "ocs-digital/wp-json/wp/v2/pages/6"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.componentHome_block {
  display: block;
  margin-top: 55px;
}
.outerImageComp {
  text-align: right;
  margin-top: -188px;
}
.computerImage {
  /* width: 100%; */
}
#skills {
  scroll-behavior: smooth;
}
.Home_text_block {
  text-align: left;
}
.lineOne {
  font-size: 49px;
  font-weight: 100;
  color: #5B8B7E;
  text-transform: uppercase;
}
.lineTwo {
  font-size: 49px;
  font-weight: 100;
  color: #FFFFFF;
  text-transform: uppercase;
}
.lineThree {
  font-size: 49px;
  font-weight: 100;
  color: #E83E4B;
  text-transform: uppercase;
}
.TopHomeback-Image {
  background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/backgroundHome.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* height: 100vh; */
  height: 35vh;
  /* background-color: #000322; */
  padding-bottom: 50px;
  padding-top: 50px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
/* @media only screen and (max-width: 1650px) and (min-width :1352px) {
  .TopHomeback-Image {
    background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/HeaderImage_OCS_Studios.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 87vh;
  }
}

@media only screen and (max-width: 1352px) {
  .TopHomeback-Image {
    background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/HeaderImage_OCS_Studios.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 75vh;
  }
} */

@media only screen and (max-width: 1200px) {
  .outerImageComp {
    display: none;
  }
  .componentHome_block {
    display: block;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .componentHome_block {
    display: block;
    margin-top: 11%;
  }
  .TopHomeback-Image {
    background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/backgroundHome.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* height: 100vh; */
    /* height: 70vh; */
    /* background-color: #000322; */
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .outerImageComp {
    display: block;
    margin-top: 0px!important;
    text-align: start!important;
  }
  .computerImage {
    width: 100%;
  }
  .lineOne {
    font-size: 39px;
  }
  .lineTwo {
    font-size: 39px;
  }
  .lineThree {
    font-size: 39px;
  }
}
@media only screen and (max-width: 350px) { 
  .componentHome_block {
    display: block;
    margin-top: 25%;
  }
}
@media only screen and (max-width: 325px) { 
  .componentHome_block {
    display: block;
    margin-top: 30%;
  }
}
</style>