<template>
  <div class="Menu">
    <b-navbar toggleable="lg" type="dark" variant="info" class="container">
        <b-navbar-brand href='/'>
            <b-img class="logo" src="https://wp-ocssport.ocs-sport.com/ocs-digital/wp-content/uploads/sites/3/2023/05/OCs_Digital_2023_Logo_V1.png"></b-img>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-item class="navItems" @click="scroll()">Our Skills</b-nav-item>
                <b-nav-item class="navItems" @click="scrolltwo()">About Us</b-nav-item>
                <b-nav-item class="navItems" @click="scrollthree()">
                    <b-button class="getInTouch" variant="danger">Get in touch</b-button>
                </b-nav-item>
            </b-navbar-nav>
            <b-navbar-brand href='https://ocs-sport.com/#/' target="_blank">
                <b-img class="supportLogos" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/05/OCS-Sport_white.png"></b-img>
            </b-navbar-brand>
            <b-navbar-brand href='https://ocs-studios.com/' target="_blank">
                <b-img class="supportLogos" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2023/05/OCS-Studios_white.png"></b-img>
            </b-navbar-brand>
        </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {

  },
  methods: {
    scroll() {
      var score = document.getElementById("skills");
      score.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    scrolltwo() {
      var score = document.getElementById("about");
      score.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    scrollthree() {
      var score = document.getElementById("contact");
      score.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    img.supportLogos {
      width: 45px;
    }
    .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
        color: rgb(232 63 75);
    }
    .navItems {
        padding-right: 30px;
        padding-left: 30px;
        line-height: 2;
        scroll-behavior: smooth;
        color:#fff;
    }
    .navItems:hover {
        padding-right: 30px;
        padding-left: 30px;
        line-height: 2;
        scroll-behavior: smooth;
        color:rgb(232 63 75);
        text-decoration: none;
    }
    .logo {
        width: 100px;
    }
    .Menu {
        background-color: #000322 !important;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .bg-info {
        background-color: #000322 !important;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: #fff;
    }
    button.getInTouch {
        background-color: #E83E4B;
        border-radius: 0px;
        width: 163px;
        height: 31px;
        line-height: 1;
    }
    button.getInTouch:hover {
        background-color: #5B8B7E;
        border-color: #5B8B7E;
    }
    @media only screen and (max-width: 500px) {
      img.supportLogos {
          width: 50px;
          margin-top: 15px;
      }
    }
</style>
